import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Inject } from '@angular/core';
import { DialogComponent, DialogService } from "ng2-bootstrap-modal";
import { CONSTANTS } from '../../../app/app.constants';
import { OrdersService, ConfigService, EmailTemplatesService } from '../../services';
import { ErrorDialogComponent } from '../../dialog/error-dialog/error-dialog.component';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { environment } from "environments/environment";

@Component({
  selector: "app-abs-quote-message",
  templateUrl: "./abs-quote-message.component.html",
  styleUrls: ["./abs-quote-message.component.scss"],
})
export class AbsQuoteMessageComponent{
  message: any = {};
  absQuoteMessage: string = "";
  selectedAbsNames = "";
  constants = CONSTANTS;
  templates: any = [];
  selectedTemplate: any = null;
  selectedEmailId: string;
  selectedProductName: any = null;
  // emailIdOptions:Array<string> = ['no-reply@pippintitle.com', 'info@pippintitle.com']
  emailIdOptions: Array<string> = environment.emailIdOptions;

  tinyOptions: any = {
    auto_focus: false,
    branding: false,
    promotion: false,
    skin: 'oxide',
    menubar: false,
    plugins: ["colorpicker", "textcolor", "link", "table", "lists", "code"], 
    toolbar: "undo redo | styleselect | forecolor backcolor | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent | link",
    content_style: `
      body { font-size: 14px; font-family: Arial, sans-serif; }
    `,
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<AbsQuoteMessageComponent>,
    private matDialog: MatDialog,
    private config: ConfigService,  
    private emailTemplateService: EmailTemplatesService) {
    this.message=this.data.message;
    // Set the default email ID to the first item in the array
    this.selectedEmailId = this.emailIdOptions.length > 0 ? this.emailIdOptions[0] : '';
  }

  ngOnInit() {
    this.initDialog();
    setTimeout(() => {
      this.getEmailTemplates();
    }, 0);
  }

  initDialog(){
    this.message = this.data.message;
  }

  confirm() {
    let subStr = /\[Abstractor Name\]/gi;
    let absFeeSubStr = /\[Fee\]/gi;
    this.absQuoteMessage = this.absQuoteMessage.replace(subStr, '<absName>');
    this.absQuoteMessage = this.absQuoteMessage.replace(absFeeSubStr, '<fee>');

    this.dialogRef.close({
        state: true,
        absQuoteMessage: this.absQuoteMessage,
        selectedEmailId: this.selectedEmailId,
        selectedProductName: this.selectedProductName,
      });
    // this.result = {
    //   state: true,
    //   absQuoteMessage: this.absQuoteMessage
    // }
    // this.close();
  }

  Close() {
    this.dialogRef.close(false);
    // this.result = false;
    // this.close();
  }

  buildAddress(orderDetail) {
    var address = "";
    if (orderDetail.Property_Address_1)
      address += orderDetail.Property_Address_1;
    if (orderDetail.Property_City) {
      if (address != "") address += ", ";
      address += orderDetail.Property_City;
    }
    if (orderDetail.Property_State_Abbr) {
      if (address != "") address += ", ";
      address += orderDetail.Property_State_Abbr;
    }
    if (orderDetail.Property_ZipCode) {
      if (address != "") address += " ";
      address += orderDetail.Property_ZipCode;
    }
    return address;
  }

  isMessageEmpty() {
    var span = document.createElement("span");
    span.innerHTML = this.absQuoteMessage;
    var x = span.textContent || span.innerText;
    if (x.trim().length == 0) return true;
    else return false;
  }

  openErrorPopup(msg) {
    this.matDialog.open(ErrorDialogComponent,{
      data: {
        message: msg
      },...this.config.getDialogOptions()
    })
    // let disposable = this.matDialog.open(ErrorDialogComponent, {
    //   message: msg
    // }, this.config.getDialogOptions()).subscribe((res) => { });
  }

  getEmailTemplates() {
    this.emailTemplateService
      .getTemplatesForProducts()
      .subscribe((templates) => {
        this.templates = templates;
        this.selectedTemplate = this.filterMappedOrDefaultTemplate();
        this.absQuoteMessage = this.draftEmailBody(this.selectedTemplate);
      });
  }

  filterMappedOrDefaultTemplate() {
    let matched;
    this.templates.forEach((template) => {
      if (template.Product_ID == this.message.orderDetails.Product_ID)
        matched = template;
    });
    if (!matched) {
      let defaultIndex = this.templates.findIndex(
        (template) => template.Template_Is_Default
      );
      if (defaultIndex > -1) matched = this.templates[defaultIndex];
    }
    return matched;
  }

  selectionChange(event) {
    if (event) this.absQuoteMessage = this.draftEmailBody(event.value);
  }

  emailSelectionChange(event){
    if(event) this.selectedEmailId = event.value
  }

  draftEmailBody(selectedTemplate) {
    let stateCounty, sellerNames, borrowerNames;
    let draft = selectedTemplate["Template_Content"];
    let sellerNamesPrimary = this.clubSellerNames();
    if (
      this.message &&
      this.message.orderDetails &&
      this.message.orderDetails.Order_ID
    ) {
      draft = draft.replace(
        /&lt;orderid&gt;/g,
        this.message.orderDetails.Order_ID
      );
    }
    if (
      this.message &&
      this.message.orderDetails &&
      (this.message.orderDetails.Property_County ||
        this.message.orderDetails.Property_State_Abbr)
    ) {
      if (
        this.message.orderDetails.Property_County &&
        this.message.orderDetails.Property_State_Abbr
      )
        stateCounty =
          this.message.orderDetails.Property_County +
          " " +
          this.message.orderDetails.Property_State_Abbr;
      if (
        !this.message.orderDetails.Property_County &&
        this.message.orderDetails.Property_State_Abbr
      )
        stateCounty = this.message.orderDetails.Property_State_Abbr;
      if (
        this.message.orderDetails.Property_County &&
        !this.message.orderDetails.Property_State_Abbr
      )
        stateCounty = this.message.orderDetails.Property_County;
      draft = draft.replace(/&lt;statecounty&gt;/g, stateCounty);
    } else {
      draft = draft.replace(/&lt;statecounty&gt;/g, " - ");
    }
    // if (
    //   this.message &&
    //   this.message.orderDetails &&
    //   this.message.orderDetails.Property_First_Name
    // ) {
    //   draft = draft.replace(
    //     /&lt;propertyownername&gt;/g,
    //     this.message.orderDetails.Property_First_Name
    //   );
    // } else {
    //   draft = draft.replace(/&lt;propertyownername&gt;/g, " - ");
    // }
    if (this.message && this.message.orderDetails) {
      draft = draft.replace(
        /&lt;fulladdress&gt;/g,
        this.buildAddress(this.message.orderDetails)
      );
    }
    if (
      this.message &&
      this.message.orderDetails &&
      (sellerNamesPrimary ||
        this.message.orderDetails.Order_Co_Seller)
    ) {
      if (
        sellerNamesPrimary &&
        this.message.orderDetails.Order_Co_Seller
      )
        sellerNames =
        sellerNamesPrimary +
          " - " +
          this.message.orderDetails.Order_Co_Seller;
      if (
        sellerNamesPrimary &&
        !this.message.orderDetails.Order_Co_Seller
      )
        sellerNames = sellerNamesPrimary;
      if (
        !sellerNamesPrimary &&
        this.message.orderDetails.Order_Co_Seller
      )
        sellerNames = this.message.orderDetails.Order_Co_Seller;
      draft = draft.replace(/&lt;sellersname&gt;/g, sellerNames);
    } else {
      draft = draft.replace(/&lt;sellersname&gt;/g, " - ");
    }
    if (
      this.message &&
      this.message.orderDetails &&
      (this.message.orderDetails.Order_Buyer ||
        this.message.orderDetails.Order_Co_Buyer)
    ) {
      if (
        this.message.orderDetails.Order_Buyer &&
        this.message.orderDetails.Order_Co_Buyer
      )
        borrowerNames =
          this.message.orderDetails.Order_Buyer +
          " - " +
          this.message.orderDetails.Order_Co_Buyer;
      if (
        this.message.orderDetails.Order_Buyer &&
        !this.message.orderDetails.Order_Co_Buyer
      )
        borrowerNames = this.message.orderDetails.Order_Buyer;
      if (
        !this.message.orderDetails.Order_Buyer &&
        this.message.orderDetails.Order_Co_Buyer
      )
        borrowerNames = this.message.orderDetails.Order_Co_Buyer;
      draft = draft.replace(/&lt;borrowersname&gt;/g, borrowerNames);
    } else {
      draft = draft.replace(/&lt;borrowersname&gt;/g, " - ");
    }
    if (
      this.message &&
      this.message.orderDetails
    ) {
      let Product_Description;
        // if(this.message.orderDetails.Product_ID){ 
        //   Product_Description = this.message.orderDetails.Product_Description;
        //   if (Product_Description && Product_Description.includes("Plus")) {
        //     var lastIndex = Product_Description.lastIndexOf(" ");
        //     Product_Description = Product_Description.substring(0, lastIndex);
        //   }
        // }
        // else {
          if(this.selectedTemplate.Template_Name) Product_Description = this.selectedTemplate.Template_Name;
          else if(this.message.orderDetails.Product_Description) Product_Description = this.message.orderDetails.Product_Description;
          if (Product_Description && Product_Description.toLowerCase().includes("template")) {
            var lastIndex = Product_Description.lastIndexOf(" ");
            Product_Description = Product_Description.substring(0, lastIndex);
          }
          if (Product_Description && Product_Description.toLowerCase().includes("plus")) {
            let lastIndex1 = Product_Description.lastIndexOf(" ");
            Product_Description = Product_Description.substring(0, lastIndex1);
          }
        // }
        this.selectedProductName = Product_Description;
        draft = draft.replace(/&lt;product&gt;/g, Product_Description);
    }
    if (
        this.message &&
        this.message.orderDetails &&
        this.message.orderDetails.Order_Parcel
      ) 
        draft = draft.replace(/&lt;parcelIds&gt;/g, this.message.orderDetails.Order_Parcel);
    else 
        draft = draft.replace(/&lt;parcelIds&gt;/g, ' - ');


    if (this.message?.clientInfo) {
          const clientInfoSnippet = `<div>${this.message.clientInfo}</div>`;
          draft = draft.replace(/&lt;client_info&gt;/g, clientInfoSnippet);
    } else {
          draft = draft.replace(/&lt;client_info&gt;/g, " - ");
    }

    if (this.message && this.message.instructions && this.message.instructions.Search_Instructions) {
      draft = draft.replace(/&lt;searchinstructions&gt;/g, this.message.instructions.Search_Instructions);
    } else {
          draft = draft.replace(/&lt;searchinstructions&gt;/g, " - ");
    }

    if (this.message && this.message.instructions && this.message.instructions.Special_Instructions) {
      draft = draft.replace(/&lt;specialinstructions&gt;/g, this.message.instructions.Special_Instructions);
    } else {
          draft = draft.replace(/&lt;specialinstructions&gt;/g, " - ");
    }

    draft = this.emailTemplateService.sanitizeTextData(draft);
    
    return draft
  }

  clubSellerNames(){
    let sellerNames = '';
    if (
      this.message.orderDetails.Order_Seller &&
      this.message.orderDetails.Property_First_Name
    )
    sellerNames =
        this.message.orderDetails.Order_Seller +
        ", " +
        this.message.orderDetails.Property_First_Name;
    if (
      this.message.orderDetails.Order_Seller &&
      !this.message.orderDetails.Property_First_Name
    )
    sellerNames = this.message.orderDetails.Order_Seller;
    if (
      !this.message.orderDetails.Order_Seller &&
      this.message.orderDetails.Property_First_Name
    )
    sellerNames = this.message.orderDetails.Property_First_Name;
    return sellerNames;
  }
}
