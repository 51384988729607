import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { DialogComponent, DialogService } from 'ng2-bootstrap-modal';
import { AuthService } from '../../services/authentication/auth.service';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatSelect } from '@angular/material/select';
import { MatSelectSearchComponent } from 'app/common/mat-select-search/mat-select-search.component';
import { UntypedFormGroup, UntypedFormBuilder, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'app-add-user2group',
  templateUrl: './add-user2group.component.html',
  styleUrls: ['./add-user2group.component.scss']
})
export class AddUser2groupComponent{
  newGrpFrm: UntypedFormGroup;
  message: any = {};
  errorMsg: any = {};
  userList: any = [];
  groupMembers: any = [];
  error: string = '';
  selectedAdmin: string = '';
  constructor(
    private fb: UntypedFormBuilder,
    private auth: AuthService,
    public dialogRef: MatDialogRef<AddUser2groupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.newGrpFrm = fb.group({
      'Group_Members': [null],
    })
    this.newGrpFrm.valueChanges.subscribe((data) => {
      this.error = '';
    });
    this.message=this.data.message;
  }

  ngOnInit() {
    this.initDialog();
    if (this.message['userList']) this.userList = this.message['userList'];
    if (this.message['activeUsers']) this.groupMembers = this.message['activeUsers'];
  }

  initDialog(){
    this.message = this.data.message;
  }

  close() {
    this.dialogRef.close(false);
    // this.result = false;
    // this.close();
  }

  selectAllUsers(ev) {
    if (ev._selected) {
      let selectAllUsers = [];
      this.userList.filter(x => {
        selectAllUsers.push(x.User_ID);
      });
      this.newGrpFrm.controls['Group_Members'].setValue(selectAllUsers)
      ev._selected = true;
    }
    if (ev._selected == false) {
      this.newGrpFrm.controls['Group_Members'].setValue([]);
    }
  }

  addUsers() {
    this.dialogRef.close({
      state: true,
      Group_Members: this.groupMembers,
    })
    // this.result = {
    //   state: true,
    //   Group_Members: this.groupMembers,
    // }
    // this.close();
  }

}
