import { Component, Inject, OnInit } from "@angular/core";
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
} from "@angular/material/dialog";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";
import { MatButtonModule } from "@angular/material/button";

@Component({
  selector: "app-doc-category",
  standalone: true,
  imports: [
    MatFormFieldModule,
    FormsModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatDialogClose,
    ReactiveFormsModule,
    MatSelectModule,
    MatButtonModule    
  ],
  templateUrl: "./doc-category.component.html",
  styleUrl: "./doc-category.component.scss",
})
export class DocCategoryComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<any>
  ) {}

  ngOnInit(): void {
    this.data.Doc_Cat_ID = this.data && this.data.file && this.data.file.doc_cat_id ? this.data.file.doc_cat_id : null
  }

  close(): void {
    this.dialogRef.close(false);
  }
}
