
import { throwError as observableThrowError, Observable, Observer, BehaviorSubject, interval } from 'rxjs';

import { mergeMap, catchError, map, filter } from 'rxjs/operators';
import { Injectable, EventEmitter, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../authentication/auth.service'
import { ConfigService } from '../config/config.service';
import { SocketService } from '../socket/socket.service';
import { QuickbooksService } from '../quickbooks/quickbooks.service'
import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http';
import { UUID } from 'angular2-uuid';
import { CONSTANTS } from '../../app.constants';
import * as _ from 'underscore';
import * as moment from 'moment';
import * as momentTimeZone from 'moment-timezone';
import { DOCUMENT } from '@angular/common';
import { environment } from 'environments/environment';
import { UsermessageService } from '../usermessage/usermessage.service';

const { Parser } = require('json2csv');

@Injectable()
export class OrdersService {
  private _checklistData$: BehaviorSubject<any> = new BehaviorSubject([]);
  public checklistData$: Observable<any> = this._checklistData$.asObservable();
  private _cmpChecklistData$: BehaviorSubject<any> = new BehaviorSubject([]);
  public cmpChecklistData$: Observable<any> = this._cmpChecklistData$.asObservable();
  private _orderCmpId$: BehaviorSubject<any> = new BehaviorSubject(null);
  public orderCmpId$: Observable<any> = this._orderCmpId$.asObservable();
  private _orderCmpName$: BehaviorSubject<any> = new BehaviorSubject(null);
  public orderCmpName$: Observable<any> = this._orderCmpName$.asObservable();

  private _orderDetails$: BehaviorSubject<any> = new BehaviorSubject(null);
  public orderDetails$: Observable<any> = this._orderDetails$.asObservable();
  private _orderProducts$: BehaviorSubject<any> = new BehaviorSubject(null);
  public orderProducts$: Observable<any> = this._orderProducts$.asObservable();
  private _orderNotes$: BehaviorSubject<any> = new BehaviorSubject(null);
  public orderNotes$: Observable<any> = this._orderNotes$.asObservable();
  private _clientSOP$: BehaviorSubject<any> = new BehaviorSubject(null);
  public clientSOP$: Observable<any> = this._clientSOP$.asObservable();
  private _countyInfo$: BehaviorSubject<any> = new BehaviorSubject(null);
  public countyInfo$: Observable<any> = this._countyInfo$.asObservable();
  private _searchInfo$: BehaviorSubject<any> = new BehaviorSubject(null);
  public searchInfo$: Observable<any> = this._searchInfo$.asObservable();
  public _assignedDurations$: BehaviorSubject<any> = new BehaviorSubject({});
  public assignedDurations$: Observable<any> = this._assignedDurations$.asObservable();

  private _documentCategories$: BehaviorSubject<any> = new BehaviorSubject([]);
  public documentCategories$: Observable<any> = this._documentCategories$.asObservable();

  bulkProgress: EventEmitter<any> = new EventEmitter()
  recvdOrderCnt = 0;
  escalatedOrderCnt = 0;
  private completeOrderData = null;
  direct: HttpClient;
  constructor(
    private router: Router,
    private auth: AuthService,
    private config: ConfigService,
    private http: HttpClient,
    private handler: HttpBackend,
    private socket: SocketService,
    private quickbooks: QuickbooksService,
    private userMessageSerice: UsermessageService,
    @Inject(DOCUMENT) private document: any
  ) {
    this.direct = new HttpClient(this.handler);
  }

  setChecklistData = (data) => this._checklistData$.next(data);
  setCmpChecklistData = (data) => this._cmpChecklistData$.next(data);
  setOrderCompanyId = (data) => this._orderCmpId$.next(data);
  setOrderCompanyName = (data) => this._orderCmpName$.next(data);

  getOrderStatus() {
    // return _.invert(CONSTANTS.orderStatus);
    var orderStat = _.invert(CONSTANTS.orderStatus);
    orderStat[15] = "assigned";
    orderStat[18] = "confirmed";
    orderStat[40] = "completed";
    return orderStat;
  }
  getPriorityOrderStatus() {
    // return _.invert(CONSTANTS.orderStatus);
    var orderStat = _.invert(CONSTANTS.orderStatus);
    orderStat[15] = "assigned";
    orderStat[40] = "completed";
    orderStat[1] = "recevied"
    orderStat[10] = "cancelled"
    orderStat[20] = "confirmed"
    orderStat[30] = 'processing'
    
    return orderStat;
  }
  getWatchOrderStatus() {
    // return _.invert(CONSTANTS.orderStatus);
    var orderStat = _.invert(CONSTANTS.orderStatus);
    orderStat[15] = "assigned";
    orderStat[40] = "completed";
    orderStat[1] = "recevied"
    orderStat[10] = "cancelled"
    orderStat[20] = "confirmed"
    orderStat[30] = 'processing'
    
    return orderStat;
  }



  getNewOrders(pageNum, sortBy, orderBy, searchStr, filterBy, selectedTags,negativeTags, isDemo, pageSize = this.config.getNumNewOrdRecordsPerPage()) {
      return this.getNewOrdersRecords(pageNum, sortBy, orderBy, searchStr, filterBy, selectedTags,negativeTags, isDemo, false, pageSize)
  }

  getNewOrdersCount(pageNum, sortBy, orderBy, searchStr, filterBy, selectedTags,negativeTags, isDemo, pageSize = this.config.getNumNewOrdRecordsPerPage()) {
    return this.getNewOrdersRecords(pageNum, sortBy, orderBy, searchStr, filterBy, selectedTags,negativeTags, isDemo, true, pageSize)
}

  getNewOrdersRecords(pageNum, sortBy, orderBy, searchStr, filterBy, selectedTags,negativeTags, isDemo, showCountFlag = false, pageSize){
    var data = {
      filterBy: filterBy,
      Selected_Tags: selectedTags,
      negativeTags,
      isDemo: isDemo
    }
    // let headers = new Headers({ 'Authorization': 'Bearer ' + this.auth.getToken() });
    // let options = new RequestOptions({ headers: headers });
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url;
    if(showCountFlag) url = this.config.getBasePath() + '/orders/admin/' + this.auth.getUserId() + '/neworder/count';
    else url = this.config.getBasePath() + '/orders/admin/' + this.auth.getUserId() + '/neworder';
    url = url + '/page/' + pageNum + '/size/' + pageSize;
    if (sortBy != '') {
      if(showCountFlag) url = url + '/sort/' + sortBy + '/orderBy/' + orderBy;
      else url = url + '/sort/' + sortBy + '/order/' + orderBy;
    }
    if (searchStr) {
      url = url + '/search/' + searchStr;
    }
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getAllOrdersForDueDate(pageNum, orderBy, sortBy, searchStr, filterBy, selectedTags, negativeTags, pageSize = this.config.getNumDueOrdRecordsPerPage()) {
    var data = {
      filterBy: filterBy,
      Date_Today: this.removeTimeComponent(),
      Date_Tomorrow: this.getTomorrowDate(),
      Selected_Tags: selectedTags,
      negative_Tags: negativeTags,
      Admin_User_ID: this.auth.getUserId()
    }
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/admin/' + this.auth.getUserId() + '/allOrders';
    url = url + '/page/' + pageNum + '/size/' + pageSize;
    if (sortBy != '') {
      url = url + '/sort/' + sortBy + '/order/' + orderBy;
    }
    if (searchStr) {
      url = url + '/search/' + searchStr;
    }
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }


  getDueOrdersCount(searchStr, filterBy, selectedTags) {
    var data = {
      filterBy: filterBy,
      Date_Today: this.removeTimeComponent(),
      Date_Tomorrow: this.getTomorrowDate(),
      Selected_Tags: selectedTags,
      Admin_User_ID: this.auth.getUserId()
    }
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/due/tags/list/admin/' + this.auth.getUserId();
    if (searchStr) {
      url = url + '/search/' + searchStr;
    }
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  removeTimeComponent() {
    let currDay = momentTimeZone.tz(new Date(), 'America/New_York').format('YYYY-MM-DD');
    var etaTimeStamp = momentTimeZone.tz(new Date(), 'Etc/UTC').format('HH:mm:ss');
    let postESTCutOff = momentTimeZone.tz(new Date(), 'America/New_York').isDST() ? '21:00:00' : '22:00:00';
    let preESTMidNight = momentTimeZone.tz(new Date(), 'America/New_York').isDST() ? '04:00:00' : '05:00:00';
    if(etaTimeStamp > postESTCutOff || etaTimeStamp <= preESTMidNight)
      currDay = momentTimeZone.tz(new Date(), 'America/New_York').add(1, 'days').format('YYYY-MM-DD');
    return `${currDay}T${postESTCutOff}`;
  }

  getTomorrowDate() {
    let nextDay = momentTimeZone.tz(new Date(), 'America/New_York').add(1, 'days').format('YYYY-MM-DD');
    var etaTimeStamp = momentTimeZone.tz(new Date(), 'Etc/UTC').format('HH:mm:ss');
    let postESTCutOff = momentTimeZone.tz(new Date(), 'America/New_York').isDST() ? '21:00:00' : '22:00:00';
    let preESTMidNight = momentTimeZone.tz(new Date(), 'America/New_York').isDST() ? '04:00:00' : '05:00:00';
    if(etaTimeStamp > postESTCutOff || etaTimeStamp <= preESTMidNight)
      nextDay = momentTimeZone.tz(new Date(), 'America/New_York').add(2, 'days').format('YYYY-MM-DD');
    return `${nextDay}T${postESTCutOff}`;
  }

  getCancelledOrders(data, isDemoFlag) {
    data.push({ isDemo: isDemoFlag })
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/admin/' + this.auth.getUserId() + '/cancelledorder';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        console.log("response:", response);
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getBackGroundNewOrders(pageNum, sortBy, orderBy, searchStr, filterBy, selectedTags) {
    var data = {
      filterBy: filterBy,
      Selected_Tags: selectedTags
    }
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/admin/' + this.auth.getUserId() + '/neworder';
    url = url + '/page/' + pageNum + '/size/' + this.config.getNumOrdRecordsPerPage();
    if (sortBy != '') {
      url = url + '/sort/' + sortBy + '/order/' + orderBy;
    }
    if (searchStr) {
      url = url + '/search/' + searchStr;
    }
    return this.direct.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getInProcessOrders(type, pageNum, sortBy, orderBy, searchStr, filterBy, selectedTags,negativeTags, isDemoFlag, pageSize = this.config.getNumOrdRecordsPerPage()) {
    var data = {
      filterBy: filterBy,
      Selected_Tags: selectedTags,
      negativeTags,
      isDemo: isDemoFlag,

    }
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url;
    if (type == "my") {
      url = this.config.getBasePath() + '/orders/admin/prcessingorders/adminId/' + this.auth.getUserId();
    } else {
      url = this.config.getBasePath() + '/orders/admin/prcessingorders/all/' + this.auth.getUserId();
    }
    // let url = this.config.getBasePath() + '/orders/admin/prcessingorders'+ this.auth.getUserId();
    url = url + '/page/' + pageNum + '/size/' + pageSize;
    if (sortBy != '') {
      url = url + '/sort/' + sortBy + '/order/' + orderBy;
    }
    if (searchStr) {
      url = url + '/search/' + searchStr;
    }
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getPriorityOrders(type, pageNum, sortBy, orderBy, searchStr, filterBy, selectedTags,negativeTags, isDemoFlag,order_Status, pageSize = this.config.getNumOrdRecordsPerPage()){
    var data = {
      filterBy: filterBy,
      Selected_Tags: selectedTags,
      isDemo: isDemoFlag,
      order_Status: order_Status,
      negativeTags
    }
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url;
    if (type == "my") {
      url = this.config.getBasePath() + '/orders/admin/priorityorders/adminId/' + this.auth.getUserId();
    } else {
      url = this.config.getBasePath() + '/orders/admin/priorityorders/all/' + this.auth.getUserId();
    }
    url = url + '/page/' + pageNum + '/size/' + pageSize;
    if (sortBy != '') {
      url = url + '/sort/' + sortBy + '/order/' + orderBy;
    }
    if (searchStr) {
      url = url + '/search/' + searchStr;
    }
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getConfirmedOrders(pageNum, sortBy, orderBy, searchStr, filterBy, selectedTags,negativeTags, isDemoFlag, pageSize = this.config.getNumOrdRecordsPerPage()) {
    var data = {
      filterBy: filterBy,
      Selected_Tags: selectedTags,
      negativeTags,
      isDemo: isDemoFlag
    }
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/admin/confirmedorders/adminId/' + this.auth.getUserId();

    url = url + '/page/' + pageNum + '/size/' + pageSize;
    if (sortBy != '') {
      url = url + '/sort/' + sortBy + '/order/' + orderBy;
    }
    if (searchStr) {
      url = url + '/search/' + searchStr;
    }
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getPausedOrders(pageNum, sortBy, orderBy, searchStr, filterBy, selectedTags,negativeTags, isDemoFlag, pageSize = this.config.getNumOrdRecordsPerPage()) {
    var data = {
      filterBy: filterBy,
      Selected_Tags: selectedTags,
      isDemo: isDemoFlag,
      negativeTags
    }
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/admin/' + this.auth.getUserId() + '/pausedOrders';
    url = url + '/page/' + pageNum + '/size/' + pageSize;
    if (sortBy != '') {
      url = url + '/sort/' + sortBy + '/order/' + orderBy;
    }
    if (searchStr) {
      url = url + '/search/' + searchStr;
    }
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getWatcedOrders(pageNum, sortBy, orderBy, searchStr, filterBy, selectedTags,negativeTags, isDemoFlag,order_Status, pageSize = this.config.getNumOrdRecordsPerPage()) {
    var data = {
      filterBy: filterBy,
      Selected_Tags: selectedTags,
      negativeTags,
      isDemo: isDemoFlag,
      order_Status:order_Status
    }
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/admin/' + this.auth.getUserId() + '/watchedOrders';
    url = url + '/page/' + pageNum + '/size/' + pageSize;
    if (sortBy != '') {
      url = url + '/sort/' + sortBy + '/order/' + orderBy;
    }
    if (searchStr) {
      url = url + '/search/' + searchStr;
    }
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getNewClientOrders(pageNum, sortBy, orderBy, searchStr, filterBy, selectedTags,negativeTags, pageSize = this.config.getNumOrdRecordsPerPage()) {
    var data = {
      filterBy: filterBy,
      Selected_Tags: selectedTags,
      negativeTags
    }
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/admin/' + this.auth.getUserId() + '/newCliOrders';
    url = url + '/page/' + pageNum + '/size/' + pageSize;
    if (sortBy != '') {
      url = url + '/sort/' + sortBy + '/order/' + orderBy;
    }
    if (searchStr) {
      url = url + '/search/' + searchStr;
    }
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getInProcessTagOrderCount(type, searchStr, filterBy, selectedTags) {
    var data = {
      filterBy: filterBy,
      Selected_Tags: selectedTags
    }
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url;
    if (type == "my") {
      url = this.config.getBasePath() + '/orders/processing/tags/list/admin/' + this.auth.getUserId();
    } else {
      url = this.config.getBasePath() + '/orders/processing/all/tags/list/admin/' + this.auth.getUserId();
    }
    if (searchStr) {
      url = url + '/search/' + searchStr;
    }
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getPriorityTagOrderCount(type, searchStr, filterBy, selectedTags, isDemo, order_Status) {
    var data = {
      filterBy: filterBy,
      Selected_Tags: selectedTags,
      isDemo,
      order_Status
    }
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url;
    if (type == "my") {
      url = this.config.getBasePath() + '/orders/priority/tags/list/admin/' + this.auth.getUserId();
    } else {
      url = this.config.getBasePath() + '/orders/priority/all/tags/list/admin/' + this.auth.getUserId();
    }
    if (searchStr) {
      url = url + '/search/' + searchStr;
    }
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }
  getEscalatedTagOrderCount(type, searchStr, filterBy, isDemo, order_Status){
    var data = {
      filterBy,
      isDemo,
      usertype:type,
      order_Status
    }
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url;
    if (type == "my") {
      url = this.config.getBasePath() + '/orders/escalated/tags/list/admin/' + this.auth.getUserId();
    } else {
      url = this.config.getBasePath() + '/orders/escalated/all/tags/list/admin/' + this.auth.getUserId();
    }
    if (searchStr) {
      url = url + '/search/' + searchStr;
    }
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }
  getVendorTagOrderCount(type, searchStr, filterBy, isDemo, order_Status){
    var data = {
      filterBy,
      isDemo,
      usertype:type,
      order_Status
    }
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url;
    if (type == "my") {
      url = this.config.getBasePath() + '/orders/vendor/tags/list/admin/' + this.auth.getUserId();
    } else {
      url = this.config.getBasePath() + '/orders/vendor/all/tags/list/admin/' + this.auth.getUserId();
    }
    if (searchStr) {
      url = url + '/search/' + searchStr;
    }
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }
  getNewTagOrderCount(searchStr, filterBy) {
    var data = {
      filterBy: filterBy
    }
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/new/tags/list/admin/' + this.auth.getUserId();
    if (searchStr) {
      url = url + '/search/' + searchStr;
    }
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getConfirmedTagOrderCount(searchStr, filterBy) {
    var data = {
      filterBy: filterBy
    }
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/confirmed/tags/list/admin/' + this.auth.getUserId();
    if (searchStr) {
      url = url + '/search/' + searchStr;
    }
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getPausedTagOrderCount(searchStr, filterBy, isDemo) {
    var data = {
      filterBy: filterBy,
      isDemo
    }
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/paused/tags/list/admin/' + this.auth.getUserId();
    if (searchStr) {
      url = url + '/search/' + searchStr;
    }
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getWatchedTagOrderCount(searchStr, filterBy,isDemo, order_Status) {
    var data = {
      filterBy: filterBy,
      isDemo, 
      order_Status
    }
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/watched/tags/list/admin/' + this.auth.getUserId();
    if (searchStr) {
      url = url + '/search/' + searchStr;
    }
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }


  getCompletedOrders(type, pageNum, sortBy, orderBy, searchStr, filterBy, isDemo, pageSize = this.config.getnoOrderSearchandComplete()) {
    var data = {
      filterBy: filterBy,
      isDemo: isDemo
    }
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url;
    if (type == "my") {
      url = this.config.getBasePath() + '/orders/admin/' + this.auth.getUserId() + '/completedorder';
    } else {
      url = this.config.getBasePath() + '/orders/admin/completedorder/all/' + this.auth.getUserId();
    }
    // let url = this.config.getBasePath() + '/orders/admin/completedorder';
    url = url + '/page/' + pageNum + '/size/' + pageSize;
    if (sortBy != '') {
      url = url + '/sort/' + sortBy + '/order/' + orderBy;
    }
    if (searchStr) {
      url = url + '/search/' + searchStr;
    }
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }


  getOrderDetails(orderId) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/order/' + orderId;
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        // PT-3792
        this._orderDetails$.next(response);
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getOrderTimeline(orderId) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/' + orderId + '/orderTimeline';
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getCustomerOrderProducts(orderId) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/' + orderId + '/orderProducts';
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        this._orderProducts$.next(response);
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getOrderNotes(orderId) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/order/' + orderId + '/notes';
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        this._orderNotes$.next(response);
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getAllNotesbyOrdId(orderId) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/order/' + orderId + '/all/notes';
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  createOrderNote(postBody) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/order/create/note';
    return this.http.post(url, postBody, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getCustomGeneratedStatus(orderId) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/order/' + orderId + '/custom/generated/status';
    return this.direct.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getOrderMessages(orderId) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/messages/admin/order/' + orderId + '/message/' + CONSTANTS.messageType.orderMessage;
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  sendInternalMessage(orderId, textModel, senderId) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/messages/internalmsg/order/' + orderId + '/user/' + senderId;
    return this.http.post(url, textModel, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  sendMessage(orderId, textModel, senderId) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/messages/admin/order/' + orderId + '/user/' + senderId;
    return this.http.post(url, textModel, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getOrderRelatedDocsTags(orderId) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/getdocs/order/' + orderId;
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));

  }

  getOrderDocuments(orderId) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/getdocument/order/' + orderId;
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  assignOrder(orderId, assignUserId, orderETA, msgText, msgType, getTimeToCompl) {
    var data = {
      Admin_User_ID: this.auth.getUserId(),
      Order_ID: orderId,
      Assigned_User_ID: assignUserId,
      Order_ETA: orderETA,
      Message_Text: msgText,
      Message_Type: msgType,
      assignType: CONSTANTS.SMCompleteMsgType.inactive,
    }
    if (getTimeToCompl) data['timeToComplete'] = getTimeToCompl;

    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/orders/assign';
    return this.http.put(url, data, httpOptions).pipe(
      map((response: any) => {
        console.log("response", response);
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  screenOrder(orderId, assignUserId, msgText, msgType, getTimeToCompl) {
    var data = {
      Admin_User_ID: this.auth.getUserId(),
      Order_ID: orderId,
      Assigned_User_ID: assignUserId,
      Message_Text: msgText,
      Message_Type: msgType,
      assignType: CONSTANTS.SMCompleteMsgType.inactive,
    }
    if (getTimeToCompl) data['timeToComplete'] = getTimeToCompl;
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/orders/screen';
    return this.http.put(url, data, httpOptions).pipe(
      map((response: any) => {
        console.log("response", response);
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  unassignOrder(orderId,msgText,msgType,orderStatus) {
    var data = {
      Admin_User_ID: this.auth.getUserId(),
      Order_ID: orderId,
      Assigned_User_ID:null,
      Message_Text: msgText,
      Message_Type: msgType,
      assignType: CONSTANTS.SMCompleteMsgType.unassign,
      Order_Status:orderStatus
    }
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/orders/change/unassign';
    return this.http.put(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  changeQuoteAssignee(orderId, assignUserId, msgText, msgType, getTimeToCompl) {
    var data = {
      Admin_User_ID: this.auth.getUserId(),
      Order_ID: orderId,
      Assigned_User_ID: assignUserId,
      Message_Text: msgText,
      Message_Type: msgType,
      assignType: CONSTANTS.SMCompleteMsgType.inactive,
    }
    if (getTimeToCompl) data['timeToComplete'] = getTimeToCompl;
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/orders/change/quote/assignee';
    return this.http.put(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  changeScreener(orderId, assignUserId, msgText, msgType, getTimeToCompl) {
    var data = {
      Admin_User_ID: this.auth.getUserId(),
      Order_ID: orderId,
      Assigned_User_ID: assignUserId,
      Message_Text: msgText,
      Message_Type: msgType,
      assignType: CONSTANTS.SMCompleteMsgType.inactive,
    }

    if (getTimeToCompl) data['timeToComplete'] = getTimeToCompl;
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/orders/change/screen';
    return this.http.put(url, data, httpOptions).pipe(
      map((response: any) => {
        console.log("response", response);
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  setPriorityForRushOrders(orderId, isRushOrder) {
    var data = {
      Order_ID: orderId,
      Rush_Order: isRushOrder,
      Admin_User_ID: this.auth.getUserId()
    }
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/orders/set/rushorder/priority';
    return this.http.put(url, data, httpOptions).pipe(
      map((response: any) => {
        console.log("response", response);
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  updateSearchMaster(orderId, assignUserId) {
    var data = {
      Admin_User_ID: this.auth.getUserId(),
      Order_ID: orderId,
      Assigned_User_ID: assignUserId,
    }
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/orders/searchmaster/change';
    return this.http.put(url, data, httpOptions).pipe(
      map((response: any) => {
        console.log("response", response);
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  changeOrderAssign(orderId, assignUserId, orderETA, msgText, msgType, getTimeToCompl, orderETARange, etaChangeReason = '', etaChangeReasonId = '', assignType = CONSTANTS.SMCompleteMsgType.inactive) {
    var data = {
      Admin_User_ID: this.auth.getUserId(),
      Order_ID: orderId,
      Assigned_User_ID: assignUserId,
      Order_ETA: orderETA,
      Message_Text: msgText,
      Message_Type: msgType,
      assignType: assignType,
      orderETAChangeReason: etaChangeReason,
      Order_ETA_Change_Reason_Type_ID: etaChangeReasonId
    }
    if (orderETARange) data['Order_ETA_Range'] = orderETARange;
    if (getTimeToCompl) data['timeToComplete'] = getTimeToCompl;
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/orders/change/assign';
    return this.http.put(url, data, httpOptions).pipe(
      map((response: any) => {
        console.log("response", response);
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  assignBulkOrds(orderIds, assignUserId, orderETA, msgText, msgType, getTimeToCompl, orderETARange, newOrdsBulkAssign = false, isDuePage = false) {
    var data = {
      Admin_User_ID: this.auth.getUserId(),
      Order_ID: orderIds,
      Assigned_User_ID: assignUserId,
      Order_ETA: orderETA,
      Message_Text: msgText,
      Message_Type: msgType,
      assignType: CONSTANTS.SMCompleteMsgType.inactive,
      newOrdsBulkAssign: newOrdsBulkAssign,
      DuePage: isDuePage,
    }
    if (orderETARange) data['Order_ETA_Range'] = orderETARange;
    if (getTimeToCompl) data['timeToComplete'] = getTimeToCompl;
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/orders/bulk/assign';
    return this.http.put(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  acceptOrder(model, OrderId) {
    model['Order_Status_Msg_Type'] = CONSTANTS.OrderStatusMsgType.isOrderMsg
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/orders/' + OrderId + '/accept';
    return this.http.put(url, model, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  editOrderPricing(model, OrderId) {
    if (!this.quickbooks.getSharedSessionData() && model.Invoice_Update) {
      return this.quickbooks.getUserToken().pipe(
        mergeMap((response: any) => {
          var parsedResponse = response;
          if (!parsedResponse.status) {
            this.quickbooks.setSharedSessionData({ url: '/edit-order-pricing/' + OrderId, data: model, continue: 'edit_order_pricing' });
            this.document.location.href = parsedResponse.url;
            return Promise.resolve(0);
          }
          else return this.editOrderPricingWithQuickbooks(model, OrderId);
        }),
        // catchError((error: any) => observableThrowError(error.error)),);
        catchError((error: any) => {
          if (error.error) return observableThrowError(error.error);
          else return observableThrowError(error);
        }));
    }
    else return this.editOrderPricingWithQuickbooks(model, OrderId);
  }

  editOrderPricingWithQuickbooks(model, OrderId) {
    this.quickbooks.resetSharedSessionData();
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/orders/' + OrderId + '/edit-pricing';
    return this.http.put(url, model, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  completeOrder(data) {
    if (!this.quickbooks.getSharedSessionData() && data.Invoice_Update) {
      return this.quickbooks.getUserToken().pipe(
        mergeMap((response: any) => {
          var parsedResponse = response;
          if (!parsedResponse.status) {
            this.quickbooks.setSharedSessionData({ url: '/orders/' + data.Order_ID + '/view', data: data, continue: 'complete_order' });
            this.document.location.href = parsedResponse.url;
            return Promise.resolve(0);
          }
          else return this.completeOrderRequest(data)
        }),
        catchError((error: any) => observableThrowError(error.error)));
    }
    else {
      return this.completeOrderRequest(data);
    }
  }

  completeOrderRequest(data) {
    this.setChecklistData([]);
    this.quickbooks.resetSharedSessionData();
    data['Admin_User_ID'] = this.auth.getUserId();
    data['Order_Status_Msg_Type'] = CONSTANTS.OrderStatusMsgType.isOrderMsg
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/orders/complete';
    return this.http.put(url, data, httpOptions).pipe(
      map((result: any) => result),
      catchError((error: any) => observableThrowError(error.error)));
  }

  verifyAndGenerateInvoice(orderId, compData, screen) {
    if (!this.quickbooks.getSharedSessionData()) {
      return this.quickbooks.getUserToken().pipe(
        mergeMap((response: any) => {
          var parsedResponse = response;
          if (!parsedResponse.status) {
            this.quickbooks.setSharedSessionData({ url: '/orders/' + orderId + '/view', data: compData, continue: 'generate_invoice', screen: screen });
            this.document.location.href = parsedResponse.url;
            return Promise.resolve(0);
          }
          else return this.generateQuickbookInvoice(orderId)
        }),
        catchError((error: any) => {
          if (error.error) return observableThrowError(error.error);
          else return observableThrowError(error);
        }));
    }
    else {
      return this.generateQuickbookInvoice(orderId);
    }
  }

  generateQuickbookInvoice(orderId) {
    this.quickbooks.resetSharedSessionData();
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    var data = {
      Order_ID: orderId,
      Admin_User_ID: this.auth.getUserId()
    }
    let url = this.config.getBasePath() + '/orders/invoice/new';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => { throw error.error }));
  }

  changeOrderStatus(orderId, orderStatus, data) {
    var payload = {} ;
    if(data && data.flag){
      payload = {
        Admin_User_ID: this.auth.getUserId(),
        Order_Status: orderStatus,
        Order_Completed_Type:data['flag'],
        Order_ID : data['Order_ID'] ,
        Customer_ID : data['Customer_ID']
      }
    }else{
      payload = {
        Admin_User_ID: this.auth.getUserId(),
        Order_Status: orderStatus
      }
    }
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/orders/changestatus/' + orderId;
    return this.http.put(url, payload, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  changeOrdAdmStatus(orderId, orderStatus) {
    var data = {
      Admin_User_ID: this.auth.getUserId(),
      Order_Status: orderStatus
    }
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/orders/changeadminstatus/' + orderId;
    return this.http.put(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  cancelOrder(orderId, comment, CustomerId, cancelOrderReasonTypeId,mailInfo) {
    var data = {
      Order_ID: orderId,
      Customer_ID: CustomerId,
      Comment: comment,
      Order_Status_Msg_Type: CONSTANTS.OrderStatusMsgType.isInternalMsg,
      Order_Cancellation_Reason_Type_ID:cancelOrderReasonTypeId,
      Cancelled_Mail : mailInfo
    }
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/orders/admin/cancelorder/' + this.auth.getUserId();
    return this.http.put(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getOrderProducts() {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/products';
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getOrderProductsForClient(userId, deleteCopyCharges = true) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/admin/products/defaultflag/' + userId;
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        if (deleteCopyCharges) {
          for (var i = 0; i < response.length; i++) {
            if (response[i].Product_ID == environment.copyChargeProductId) response.splice(i, 1);
          }
          for (var i = 0; i < response.length; i++) {
            if (response[i].Product_Status == CONSTANTS.selectProductStatus.subproduct) response.splice(i, 1);
          }
        }
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getOrderProductsForClientBulk(userId, deleteCopyCharges = true) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/products/defaultflagbulk/' + userId;
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        if (deleteCopyCharges) {
          for (var i = 0; i < response.length; i++) {
            if (response[i].Product_ID == environment.copyChargeProductId) response.splice(i, 1);
          }
          for (var i = 0; i < response.length; i++) {
            if (response[i].Product_Status == CONSTANTS.selectProductStatus.subproduct) response.splice(i, 1);
          }
        }
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getAllOrderProducts() {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/products/all';
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error)));
  }

  editOrder(data, adminId) {
    data.Admin_ID = adminId;
    data.Order_ETA = data.Order_ETA_EST;
    data.Order_Mortgage_Date = data.Order_Mortgage_Date_EST;
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/orders/' + data.Order_ID + '/edit';
    return this.http.put(url, data, httpOptions).pipe(
      map((response: any) => {
        console.log("response", response);
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getAllReceivedOrderCount() {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/receivedordercount';
    return this.direct.post(url, { Admin_ID: this.auth.getUserId() }, httpOptions).pipe(
      map((response: any) => {
        this.recvdOrderCnt = response;
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getAllBackgroundReceivedOrderCount() {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/receivedordercount';
    return this.direct.post(url, { Admin_ID: this.auth.getUserId() }, httpOptions).pipe(
      map((response: any) => {
        this.recvdOrderCnt = response;
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  searchOrder(model) {
    let data = this.searchOrderFunctionality(model);
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/search';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  searchOrderCount(model) {
    let data = this.searchOrderFunctionality(model);
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/search/count';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  searchOrderFunctionality(model){
    var data = Object.assign({}, model);
    if (model['fromDate']) data['fromDate'] = this.getFromDateInEST(data['fromCreated_DateTime']);
    if (model['toDate']) data['toDate'] = this.getToDateTimeInEST(data['toCreated_DateTime']);
    if (model['fromCompletedDate']) data['fromCompletedDate'] = this.getFromDateInEST(data['fromCompleted_DateTime']);
    if (model['toCompletedDate']) data['toCompletedDate'] = this.getToDateTimeInEST(data['toCompleted_DateTime']); //adding 59 seconds to toDate
    if (model['fromETADate']) data['fromETADate'] = this.getFromDateInEST(data['fromOrdETA_DateTime']);
    if (model['toETADate']) data['toETADate'] = this.getToDateTimeInEST(data['toOrdETA_DateTime']);
    if (model['Customer_ID'] == 'all') data['Customer_ID'] = "";
    if (model['states'] == 'all') data['states'] = [];
    if (model['counties'] == 'all') data['counties'] = [];
    data.Order_Status = this.getformatedOrderStatus(model['Order_Status']);
    return data;
  }

  getFromDateInEST(date) {
    var estShift = momentTimeZone.tz(date, 'America/New_York').isDST() ? 4 : 5;
    var etaInEstTimezone = moment(date).utcOffset(moment(date).utcOffset()).add(estShift, 'hours').format('YYYY-MM-DDTHH:mm:ss');
    return etaInEstTimezone;
  }

  getToDateInEST(date) {
    var estShift = momentTimeZone.tz(date, 'America/New_York').isDST() ? 4 : 5;
    var etaInEstTimezone = moment(date).utcOffset(moment(date).utcOffset()).add(estShift, 'hours').add(23, 'hours').add(59, 'minutes').add(59, 'seconds').format('YYYY-MM-DDTHH:mm:ss');
    return etaInEstTimezone;
  }


  getToDateTimeInEST(date) {
    var estShift = momentTimeZone.tz(date, 'America/New_York').isDST() ? 4 : 5;
    var etaInEstTimezone = moment(date).utcOffset(moment(date).utcOffset()).add(estShift, 'hours').add(59, 'seconds').format('YYYY-MM-DDTHH:mm:ss');
    return etaInEstTimezone;
  }

  createExcel(model) {
    var data = Object.assign({}, model);
    if (model['fromDate']) data['fromDate'] = this.getFromDateInEST(data['fromCreated_DateTime']);
    if (model['toDate']) data['toDate'] = this.getToDateTimeInEST(data['toCreated_DateTime']);
    if (model['fromCompletedDate']) data['fromCompletedDate'] = this.getFromDateInEST(data['fromCompleted_DateTime']);
    if (model['toCompletedDate']) data['toCompletedDate'] = this.getToDateTimeInEST(data['toCompleted_DateTime']);
    if (model['fromETADate']) data['fromETADate'] = this.getFromDateInEST(data['fromOrdETA_DateTime']);
    if (model['toETADate']) data['toETADate'] = this.getToDateTimeInEST(data['toOrdETA_DateTime']);
    data.Order_Status = this.getformatedOrderStatus(model['Order_Status']);
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      responseType: 'blob' as 'json',
    }

    let url = this.config.getBasePath() + '/reports/create';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  createNewordersExcelReport(filterBy, searchStr, sortField, orderBy, selectedTags,negativeTags, isDemoFlag) {
    var data = {
      filterBy: filterBy,
      searchStr: searchStr,
      Selected_Tags: selectedTags,
      negativeTags,
      Admin_User_ID: this.auth.getUserId(),
      sortField: sortField,
      orderBy: orderBy,
      isDemo: isDemoFlag
    }
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      responseType: 'blob' as 'json',
    }
    let url = this.config.getBasePath() + '/reports/neworders/create';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  createProgressOrdsExcelReport(filterBy, searchStr, sortField, orderBy, selectedTags,negativeTags, listingType, isDemoFlag) {
    var data = {
      filterBy: filterBy,
      searchStr: searchStr,
      Admin_User_ID: this.auth.getUserId(),
      Selected_Tags: selectedTags,
      negativeTags,
      sortField: sortField,
      orderBy: orderBy,
      listingType: listingType,
      Admin_User_Role: this.auth.getUserRole(),
      isDemo: isDemoFlag
    }
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      responseType: 'blob' as 'json',
    }
    let url = this.config.getBasePath() + '/reports/progressOrds/create/excel';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  createVendorOrdsExcelReport(filterBy, searchStr, sortField, orderBy, selectedTags,negativeTags, listingType, isDemoFlag) {
    var data = {
      filterBy: filterBy,
      searchStr: searchStr,
      Admin_User_ID: this.auth.getUserId(),
      Selected_Tags: selectedTags,
      negativeTags,
      sortField: sortField,
      orderBy: orderBy,
      listingType: listingType,
      Admin_User_Role: this.auth.getUserRole(),
      isDemo: isDemoFlag,
    }
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      responseType: 'blob' as 'json',
    }
    let url = this.config.getBasePath() + '/reports/vendorOrders/create/excel';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }


  createPriorityOrdsExcelReport(filterBy, searchStr, sortField, orderBy, selectedTags,negativeTags, listingType, isDemoFlag,order_Status) {
    var data = {
      filterBy: filterBy,
      searchStr: searchStr,
      Admin_User_ID: this.auth.getUserId(),
      Selected_Tags: selectedTags,
      negativeTags,
      sortField: sortField,
      orderBy: orderBy,
      listingType: listingType,
      Admin_User_Role: this.auth.getUserRole(),
      isDemo: isDemoFlag,
      order_Status: order_Status
    }
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      responseType: 'blob' as 'json',
    }
    let url = this.config.getBasePath() + '/reports/priorityOrds/create/excel';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  createConfirmedOrdsExcelReport(filterBy, searchStr, sortField, orderBy, selectedTags,negativeTags, isDemoFlag) {
    var data = {
      filterBy: filterBy,
      searchStr: searchStr,
      Admin_User_ID: this.auth.getUserId(),
      Selected_Tags: selectedTags,
      negativeTags,
      sortField: sortField,
      orderBy: orderBy,
      Admin_User_Role: this.auth.getUserRole(),
      isDemo: isDemoFlag
    }
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      responseType: 'blob' as 'json',
    }
    let url = this.config.getBasePath() + '/reports/confirmOrds/create/excel';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  createPausedOrdsExcelReport(filterBy, searchStr, sortField, orderBy, selectedTags, negativeTags, isDemoFlag) {
    var data = {
      filterBy: filterBy,
      searchStr: searchStr,
      Admin_User_ID: this.auth.getUserId(),
      Selected_Tags: selectedTags,
      negativeTags,
      sortField: sortField,
      orderBy: orderBy,
      Admin_User_Role: this.auth.getUserRole(),
      isDemo: isDemoFlag
    }
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      responseType: 'blob' as 'json',
    }
    let url = this.config.getBasePath() + '/reports/pausedOrds/create/excel';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  createWatchedOrdsExcelReport(filterBy, searchStr, sortField, orderBy, selectedTags,negativeTags, isDemoFlag, order_Status) {
    var data = {
      filterBy: filterBy,
      searchStr: searchStr,
      Admin_User_ID: this.auth.getUserId(),
      Selected_Tags: selectedTags,
      negativeTags,
      sortField: sortField,
      orderBy: orderBy,
      Admin_User_Role: this.auth.getUserRole(),
      isDemo: isDemoFlag,
      order_Status: order_Status
    }
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      responseType: 'blob' as 'json',
    }
    let url = this.config.getBasePath() + '/reports/watchedOrds/create/excel';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  createNewClientOrdsExcelReport(filterBy, searchStr, sortField, orderBy, selectedTags, negativeTags) {
    var data = {
      filterBy: filterBy,
      searchStr: searchStr,
      Admin_User_ID: this.auth.getUserId(),
      Selected_Tags: selectedTags,
      negativeTags,
      sortField: sortField,
      orderBy: orderBy,
      Admin_User_Role: this.auth.getUserRole()
    }
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      responseType: 'blob' as 'json',
    }
    let url = this.config.getBasePath() + '/reports/newClientOrders/create/excel';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  createOrdersDueExcelReport(orderBy, sortField, searchStr, filterBy, selectedTags, negativeTags ) {
    var data = {
      Date_Today: this.removeTimeComponent(),
      Date_Tomorrow: this.getTomorrowDate(),
      filterBy: filterBy,
      searchStr: searchStr,
      Admin_User_ID: this.auth.getUserId(),
      Selected_Tags: selectedTags,
      sortField: sortField,
      orderBy: orderBy,
      negative_Tags: negativeTags,
    }
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      responseType: 'blob' as 'json',
    }
    let url = this.config.getBasePath() + '/reports/ordersDue/create/excel';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  createCompletedOrdsExcelReport(filterBy, searchStr, sortField, orderBy, listingType, isDemoFlag) {
    var data = {
      filterBy: filterBy,
      searchStr: searchStr,
      Admin_User_ID: this.auth.getUserId(),
      sortField: sortField,
      orderBy: orderBy,
      listingType: listingType,
      isDemo: isDemoFlag
    }
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      responseType: 'blob' as 'json',
    }
    let url = this.config.getBasePath() + '/reports/completedOrds/create/excel';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  createCancelledOrdsExcelReport(filterBy, searchStr, sortField, orderBy, listingType, isDemoFlag) {
    var data = {
      filterBy: filterBy,
      searchStr: searchStr,
      Admin_User_ID: this.auth.getUserId(),
      sortField: sortField,
      orderBy: orderBy,
      listingType: listingType,
      isDemo: isDemoFlag
    }
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      responseType: 'blob' as 'json',
    }
    let url = this.config.getBasePath() + '/reports/cancelledOrds/create/excel';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }


  checkOrderPermission(data) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/checkpermission';
    return this.direct.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  checkOrderProperty(orderId) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/' + orderId + '/property/check';
    return this.direct.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getOrderDocumentUrls(orderId) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/' + orderId + '/documents/url';
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error)));
  }

  createBulk(file, customerId) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let formData: FormData = new FormData();
    let progressId = UUID.UUID();
    formData.append('file', file, file.name);
    formData.append('Customer_ID', customerId);
    formData.append('Order_Created_By', this.auth.getUserId());
    formData.append('Progress_ID', progressId);
    if (this.socket['socket'] && this.socket['socket'].ioSocket && this.socket['socket'].ioSocket.id)
      formData.append('Socket_ID', this.socket['socket'].ioSocket.id);
    let url = this.config.getBasePath() + '/orders/bulk';

    this.handleProgressEvent(progressId);
    return this.direct.post(url, formData, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  uploadBulkOrders(orders, customerId, Organization_ID, Order_Source_Type_ID = null,Order_Mode_Type_ID = 1,Order_Qualia_Internal_ID = null) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    var data = {
      orders: orders,
      Customer_ID: customerId,
      Organization_ID,
      Order_Source_Type_ID : Order_Source_Type_ID,
      Order_Mode_Type_ID : Order_Mode_Type_ID,
      Order_Qualia_Internal_ID : Order_Qualia_Internal_ID,
      Order_Created_By: this.auth.getUserId(),
    }

    let url = this.config.getBasePath() + '/orders/bulk/upload';

    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  extractPDFData(file, customerId) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let formData: FormData = new FormData();
    let progressId = UUID.UUID();
    formData.append('file', file, file.name);
    formData.append('Customer_ID', customerId);
    formData.append('Order_Created_By', this.auth.getUserId());
    formData.append('Progress_ID', progressId);
    // if (this.socket['socket'] && this.socket['socket'].ioSocket && this.socket['socket'].ioSocket.id)
    //   formData.append('Socket_ID', this.socket['socket'].ioSocket.id);
    let url = this.config.getBasePath() + '/orders/pdf/data/extract';

    // this.handleProgressEvent(progressId);
    return this.http.post(url, formData, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  createOrdFromPDF(ordData) {
    ordData.adminID = this.auth.getUserId();

    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/bulk/pdf';
    return this.http.put(url, ordData, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }


  handleProgressEvent(progressId) {
    this.socket.syncMessage("bulkProgress")
      .subscribe((result: any) => {
        if (result.id == progressId) {
          this.bulkProgress.emit(result.data)
        }
      })
  }

  setCompleteOrderData(data) {
    this.completeOrderData = data
  }


  getCompleteOrderData() {
    return this.completeOrderData;
  }

  resetCompleteOrderData() {
    this.completeOrderData = null;
  }

  updateOrderDocsWithType(docId, docType, category) {
    var data = {
      Admin_User_ID: this.auth.getUserId(),
      Order_Document_ID: docId,
      Document_Type: docType,
      Doc_Cat_ID : category 
    }
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/update/orderdoctype';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  updateOrderState(orderId, orderState, comment, orderETA, orderETARange, etaChangeReason = '', etaChangeReasonId = '') {
    var data = {
      Admin_User_ID: this.auth.getUserId(),
      Order_ID: orderId,
      Order_State: orderState,
      Action_Comment: comment,
      Message_Type: CONSTANTS.messageType.internalMessage,
      Order_ETA: orderETA,
      Order_ETA_Range: orderETARange,
      orderETAChangeReason: etaChangeReason,
      Order_ETA_Change_Reason_Type_ID: etaChangeReasonId
    }
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/change/state';
    return this.http.put(url, data, httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error))
    )
  }

  getformatedOrderStatus(statuses) {
    var formattedStatus;
    if (statuses == 'all') formattedStatus = 0;
    else {
      if (statuses.indexOf(',') > -1) {
        formattedStatus = statuses.split(',');
      }
      else formattedStatus = statuses;
    }
    return formattedStatus;
  }

  updateOrderTags(data) {
    data.Admin_ID = this.auth.getUserId();
    let httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.auth.getToken()
      })
    }
    let url = this.config.getBasePath() + '/orders/tag/update';
    return this.http.put(url, data, httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error))
    )
  }
  modifyOrderTags(data) {
    data.Admin_ID = this.auth.getUserId();
    let httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.auth.getToken()
      })
    }
    let url = this.config.getBasePath() + '/orders/tag/modify';
    return this.http.put(url, data, httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error))
    )
  }

  modifyQuoteTags(data) {
    data.Admin_ID = this.auth.getUserId();
    let httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.auth.getToken()
      })
    }
    let url = this.config.getBasePath() + '/orders/quote/tag/modify';
    return this.http.put(url, data, httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error))
    )
  }










  createTagOnFly(data) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.auth.getToken()
      })
    }
    let url = this.config.getBasePath() + '/orders/tag/new/onfly';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error))
    )
  }

  getOrderTags(orderId) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/order/getOrdTags/' + orderId;
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }


  getOrderSource(orderId) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/order/'+ orderId + '/source';
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getOrderSourceList() {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/source/list';
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getOrderMode(orderId) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/order/'+ orderId + '/mode';
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getOrderModeList() {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/mode/list';
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getOrderExpenses(orderId) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/' + orderId + '/orderExpenses';
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getOrderExpenseDetails(orderId) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/' + orderId + '/orderExpenseDetails';
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getAllExpenses() {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/all/expenses';
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getAllExpenseTypes() {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/all/expenseTypes';
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error)));
  }

  manageOrderExpense(orderId, data) {
    // const deleteProperty = ({ [key]: _, ...newObj }, key) => newObj;
    const deleteProperty = (key: any, { [key]: _, ...newObj }) => newObj;
    let finalData = [];
    data.forEach((exp) => {
      // var wihoutCurrInp = deleteProperty(exp, 'currInput');   
      var wihoutCurrInp = deleteProperty('currInput', exp);    
      finalData.push(wihoutCurrInp);
    })
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/' + orderId + '/edit-expense';
    return this.http.put(url, { Order_Expenses: finalData, Admin_User_ID: this.auth.getUserId() }, httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error)));
  }

  downloadCSVfile(fields, orders, allActiveTags) {
    orders.forEach((value, index) => {
      value.serialNumber = (index + 1).toString();
      if (value.Order_Creation_Date) value.Order_Creation_Date_EST = new Date(value.Order_Creation_Date).toLocaleString("en-US", { timeZone: "America/New_York" });
      if (value.Order_ETA) value.Order_ETA_EST = new Date(value.Order_ETA).toLocaleString("en-US", { timeZone: "America/New_York" });
      if (value.Order_Completion_Date) value.Order_Completion_Date_EST = new Date(value.Order_Completion_Date).toLocaleString("en-US", { timeZone: "America/New_York" });
      if (value.Order_Cancellation_Date) value.Order_Cancellation_Date_EST = new Date(value.Order_Cancellation_Date).toLocaleString("en-US", { timeZone: "America/New_York" });
      if (value.Order_Internal_Due_Time) value.Order_Internal_Due_Time_EST = new Date(value.Order_Internal_Due_Time).toLocaleString("en-US", { timeZone: "America/New_York" });
      if (value.Order_Original_ETA) value.Order_Original_ETA_EST = new Date(value.Order_Original_ETA).toLocaleString("en-US", { timeZone: "America/New_York" });
      if (allActiveTags) value.Order_Tag_Name = this.mapTagIDToTagName(value, allActiveTags);
      if (value.Quote_ETA) value.Quote_ETA_EST = new Date(value.Quote_ETA).toLocaleString("en-US", { timeZone: "America/New_York" });
      value.qualiaOrder = this.checkQualiaFlagStatus(value);
    });

    const json2csvParser = new Parser({ fields });
    const csv = json2csvParser.parse(orders);

    var hiddenElement = document.createElement('a');
    document.body.appendChild(hiddenElement);
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv);
    hiddenElement.target = '_blank';
    hiddenElement.download = 'CSVreport.csv';
    hiddenElement.click();
    document.body.removeChild(hiddenElement);
  }

  mapTagIDToTagName(orderDetails, allActiveTags) {
    if (orderDetails.Order_Tags) {
        let tagsName = "";
        if(orderDetails.Tag_Names && orderDetails.Tag_Names.length > 0) {
            orderDetails.Tag_Names.forEach((tag) => {
                if (tagsName == "") tagsName = tag.Tag_Name;
                else tagsName = tagsName.concat(" | ", tag.Tag_Name)
            })
        }
        return tagsName;
    } else return null;
    // if (orderDetails.Order_Tags) {
    //   let splitTagFormatted = orderDetails.Order_Tags.split(",");
    //   splitTagFormatted = splitTagFormatted.filter((tag) => tag !== "");
    //   if (splitTagFormatted.length >= 1) {
    //     let tagsName = "";
    //     splitTagFormatted.forEach((x) => {
    //       allActiveTags.filter((tag) => {
    //         if (tag.Tag_ID == x) {
    //           if (tagsName == "") tagsName = tag.Tag_Name;
    //           else tagsName = tagsName.concat(" | ", tag.Tag_Name)
    //         }
    //       })
    //     })
    //     return tagsName
    //   }
    // } else return null;
  }

checkQualiaFlagStatus(order) {
  let qualiaOrgId = this.config.getQualiaOrgId();
    if (order) {
        if ((order.Organization_ID && order.Organization_ID === qualiaOrgId)) {
            return 'Yes';
        } else {
            if (order.Qualia_Flag && order.Qualia_Flag == 1) return 'Yes';
            else return 'No';
        }
    } else return '';
}

  getChecklistFromGsheet(orderId) {
    var data = {
      orderId: orderId
    }
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/gsheets/checkList';
    return this.direct.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getCountyInfoFromGsheet(state) {
    var data = {
      state: state
    }
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/gsheets/countyInfo';
    return this.direct.post(url, data, httpOptions).pipe(
      map((response: any) => {
        this._countyInfo$.next(response);
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getClientInfoFromGsheet(orgId) {
    var data = {
      Organization_ID: orgId
    }
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/gsheets/clientInfo';
    return this.direct.post(url, data, httpOptions).pipe(
      map((response: any) => {
        this._clientSOP$.next(response);
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getBillingInfoFromGsheet(orgId) {
    var data = {
      Organization_ID: orgId
    }
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/gsheets/billingInfo';
    return this.direct.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getRelatedSerarchOrders(orderDetails) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/related/search';
    return this.direct.post(url, orderDetails, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getPreviousSearchedOrders(orderDetails) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/previous/search';
    return this.http.post(url, orderDetails, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getSearchInfo(orderDetails) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/gsheets/searchinfo';
    return this.direct.post(url, orderDetails, httpOptions).pipe(
      map((response: any) => {
        this._searchInfo$.next(response);
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getDataDepthInfo(orderDetails) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/gsheets/dataDepth';
    return this.direct.post(url, orderDetails, httpOptions).pipe(
      map((response: any) => {
        this._searchInfo$.next(response);
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getOrderHistoryFromDB(orderId) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/history/' + orderId;
    return this.direct.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getETAChangeReasonsById(orderId) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/order/' + orderId + '/eta/changeReasons';
    return this.direct.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getAllSystemDocumentsTag() {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/tagdocument';
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  rejectOrder(orderId, messageText, messageType, rejectOrderFlag, SMCompleteMsgType) {
    let rejectOrderData = {
      Order_ID: orderId,
      Message_Text: messageText,
      Message_Type: messageType,
      Admin_User_ID: this.auth.getUserId(),
      assignType: SMCompleteMsgType,
      Reject_Order_Flag: rejectOrderFlag
    }
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/rejectorder';
    return this.http.post(url, rejectOrderData, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  checkAddressAPI(orderAddress) {
    let url = "https://maps.googleapis.com/maps/api/geocode/json?"
    url += "address=" + encodeURIComponent(orderAddress) + "&components=country:US&key=" + this.config.getGoogleAPIKEY();
    return this.http.get(url).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error)),
    );
  }

  updateValidateAddress(validAddressObj) {
    validAddressObj['Admin_User_ID'] = this.auth.getUserId();
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/updateaddress';
    return this.http.post(url, validAddressObj, httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error)));
  }

  initOrderProcess(orderId, assignUserId, orderETA, msgText, msgType, getTimeToCompl, orderETARange, etaChangeReason = '', etaChangeReasonId = '') {
    var data = {
      Admin_User_ID: this.auth.getUserId(),
      Order_ID: orderId,
      Assigned_User_ID: assignUserId,
      Order_ETA: orderETA,
      Message_Text: msgText,
      Message_Type: msgType,
      assignType: CONSTANTS.SMCompleteMsgType.inactive,
      orderETAChangeReason: etaChangeReason,
      Order_ETA_Change_Reason_Type_ID: etaChangeReasonId
    }
    if (orderETARange) data['Order_ETA_Range'] = orderETARange;
    if (getTimeToCompl) data['timeToComplete'] = getTimeToCompl;
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/orders/process';
    return this.http.put(url, data, httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error)));
  }

  updateCurrentTaskDue(orderId) {
    var data = {
      Admin_User_ID: this.auth.getUserId(),
      Order_ID: orderId,
    }
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/orders/taskduedate';
    return this.http.put(url, data, httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getChecklistTypes() {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/checklisttypes';
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getETAChangeReasonTypes() {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/eta/change/reasonTypes';
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  uploadDocumentForCancel(inoviceDetails) {
    let data = {
      Invoice_Details: inoviceDetails,
      Admin_User_ID: this.auth.getUserId()
    }
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/upload/invoice';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getOrderReportedError(orderId) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/order/' + orderId + '/getReported/error';
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  reportOrderError(orderId, comment, ordErrReportedID) {
    var data = {
      Order_ID: orderId,
      Comment: comment,
      ordErrReportedID: ordErrReportedID,
      Admin_User_ID: this.auth.getUserId(),
    }
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/orders/report/error';
    return this.http.put(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  canceledOrderMail(data) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/orders/canceledOrderMail';
    return this.http.put(url, data, httpOptions).pipe(
      map((result: any) => {
        return result
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getAssociatedTags(orderStatus) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/orderstatus/associatedtag';
    return this.http.post(url, orderStatus, httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getDueOrderAssociatedTags(filterBy) {
    var data = {
      filterBy: filterBy,
      Date_Today: this.removeTimeComponent(),
      Date_Tomorrow: this.getTomorrowDate(),
      Admin_User_ID: this.auth.getUserId()
    }
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/orderstatus/associatedtag';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getOrderAssignment(orderId) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/assignment/' + orderId;
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getStatus(orderId) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/status/' + orderId;
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  createEndOfDayReport() {
    var data = {};
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      responseType: 'blob' as 'json',
    }
    let url = this.config.getBasePath() + '/reports/dayreport/create/excel';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  createVolumeReport() {
    var currentDateAndTime = momentTimeZone.tz('America/New_York').format('YYYY-MM-DD')
    var data = {
      Date: currentDateAndTime
    };
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
    }
    let url = this.config.getBasePath() + '/reports/volume/report';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  createNPSReport() {
    var data = {};
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
    }
    let url = this.config.getBasePath() + '/reports/nps/report';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  createClientReport() {
    var data = {};
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
    }
    let url = this.config.getBasePath() + '/reports/client/report';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }
  
  createAttendanceReport() {
    var data = {};
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
    }
    let url = this.config.getBasePath() + '/reports/attendance/report';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  createProductivityReport() {
    var currentDateAndTime = momentTimeZone.tz('America/New_York').format('YYYY-MM-DD')
    var data = {
      Date: currentDateAndTime
    };
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
    }
    let url = this.config.getBasePath() + '/reports/productivity/report';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  deleteOrderError(orderId, comment, ordErrReportedID) {
    var data = {
      Order_ID: orderId,
      Comment: comment,
      ordErrReportedID: ordErrReportedID,
      Admin_User_ID: this.auth.getUserId(),
    }
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/orders/error/delete';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  sendBackToScreener(orderId, orderStatus, orderAutomated) {
    var data = {
      Order_ID: orderId,
      Order_Status: orderStatus,
      Order_Automated: orderAutomated,
      Modified_By: this.auth.getUserId(),
    }
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/orders/send/backto/screening';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  createVendorReport(filterBy) {
    let creationFromDate,creationToDate,completionFromDate,CompletionToDate;
    var currentDateAndTime = momentTimeZone.tz('America/New_York').format('YYYY-MM-DD');
    if(filterBy.fromDate || filterBy.toDate) {
       creationFromDate = this.getVendorConvertUsCreationFromTime(filterBy.fromDate)
      if(filterBy.toDate) creationToDate = this.getVendorConvertUsCreationToTime(filterBy.toDate)
   else currentDateAndTime = momentTimeZone.tz('America/New_York').format('YYYY-MM-DD');
    }else{
      creationFromDate = null;
      creationToDate = null;
    }

    if(filterBy.fromCompletedDate || filterBy.toCompletedDate){ completionFromDate = this.getVendorConvertUsFromTime(filterBy.fromCompletedDate)
    if(filterBy.toCompletedDate) CompletionToDate = this.getVendorConvertUsToTime(filterBy.toCompletedDate)
   else  currentDateAndTime = momentTimeZone.tz('America/New_York').format('YYYY-MM-DD');
    }else{
      completionFromDate = null;
      CompletionToDate = null;
    }
  
      var data = {
      Date: currentDateAndTime,
      vendorDateFrom: creationFromDate,
      vendorDateTo :creationToDate,
      vendorCompletedDateFrom : completionFromDate,
      vendorCompletedDateTo : CompletionToDate
    };
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
    }
    let url = this.config.getBasePath() + '/reports/vendor/report';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getVendorConvertUsCreationFromTime(date) {
    var estShift = momentTimeZone.tz(date, 'America/New_York').isDST() ? 4 : 5;
    var etaInEstTimezone = moment(date).utcOffset(moment(date).utcOffset()).add(estShift, 'hours').format('YYYY-MM-DD HH:mm:ss');
    return etaInEstTimezone;
  }

  getVendorConvertUsCreationToTime(date) {
    var estShift = momentTimeZone.tz(date, 'America/New_York').isDST() ? 4 : 5;
    var etaInEstTimezone = moment(date).utcOffset(moment(date).utcOffset()).add(estShift, 'hours').add(59, 'seconds').format('YYYY-MM-DD HH:mm:ss');
    return etaInEstTimezone;
  }



  getVendorConvertUsFromTime(date) {
    var estShift = momentTimeZone.tz(date, 'America/New_York').isDST() ? 4 : 5;
    var etaInEstTimezone = moment(date).utcOffset(moment(date).utcOffset()).add(estShift, 'hours').format('YYYY-MM-DD HH:mm:ss');
    return etaInEstTimezone;
  }

  getVendorConvertUsToTime(date) {
    var estShift = momentTimeZone.tz(date, 'America/New_York').isDST() ? 4 : 5;
    var etaInEstTimezone = moment(date).utcOffset(moment(date).utcOffset()).add(estShift, 'hours').add(59, 'seconds').format('YYYY-MM-DD HH:mm:ss');
    return etaInEstTimezone;
  }




  getBaseProductDetails(productId, orderId) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
    }
    let url = this.config.getBasePath() + '/orders/' + orderId + "/product/" + productId;
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getTrackerProRequest(orderId) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/trackerpro/request/' + orderId;
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  submitTrackerProOrder(data) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/trackerpro/submit/';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  submitTrackerProInvoice(data) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/trackerpro/invoice/submit/';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getNonBillingOrders(pageNum, sortField, orderBy, searchStr, pageSize = this.config.getnoOrderSearchandComplete()) {
    let data = {};
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/admin/' + this.auth.getUserId() + '/billing';
    url = url + '/page/' + pageNum + '/size/' + pageSize;
    if (sortField != '') {
      url = url + '/sort/' + sortField + '/order/' + orderBy;
    }
    if (searchStr) {
      url = url + '/search/' + searchStr;
    }
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error)));
  }

  createNonBillingOrdReport(searchStr, sortField, orderBy) {
    var data = {
      searchStr: searchStr,
      Admin_User_ID: this.auth.getUserId(),
      sortField: sortField,
      orderBy: orderBy
    }
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      responseType: 'blob' as 'json',
    }
    let url = this.config.getBasePath() + '/reports/billings/create';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  createPriorityOrdReport(searchStr, sortField, orderBy) {
    var data = {
      searchStr: searchStr,
      Admin_User_ID: this.auth.getUserId(),
      sortField: sortField,
      orderBy: orderBy
    }
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      responseType: 'blob' as 'json',
    }
    let url = this.config.getBasePath() + '/reports/priority/create';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  checkIfOrderReadyForBilling(orderId){
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
    }
    let url = this.config.getBasePath() + '/orders/' + orderId + "/checkIfBillingReady";
    return this.direct.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  markOrderBilled(orderId){
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
    }
    let url = this.config.getBasePath() + '/orders/markAsBilled';
    return this.http.put(url, { Order_ID : orderId } ,httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }


  createXML(order) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      responseType: 'blob' as 'json',
    };
    let url;
    if (order.Organization_ID === environment.westcorOrgId) {
      url = `${this.config.getBasePath()}/reportbuilder/westcorxml/${order.Order_ID}/${order.Report_Version}`;
    } else {
      url = `${this.config.getBasePath()}/reportbuilder/reswarexml/${order.Order_ID}/${order.Report_Version}`;
    }
  
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error))
    );
  }

  getAllOrderStatus(orderId){
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/status/all/' + orderId;
    return this.http.get(url,httpOptions).pipe(
      map((response: any) =>  response),
      catchError((error: any) => observableThrowError(error.error)));
    
  }

  storeFeeResonOnHistory(res, orderDetails, feeApproval){
    let data = {
      reason: res.comment,
      orderDetail: orderDetails,
      adminId:  this.auth.getUserId(),
      Is_FeeApproval: feeApproval,
    }
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/reason/' + this.auth.getUserId() + '/feeChange';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  taxNassessorReport() {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      responseType: 'blob' as 'json',
    }
    let url = this.config.getBasePath() + '/reports/taxassessor/report';
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getReportDescriptin(){
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/reports/description';
    return this.http.get(url,httpOptions).pipe(
      map((response: any) =>  {
        return response
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getCancelOrderReasonTypes() {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/cancel/order/reasonTypes';
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }


  getAllBorrowerDetails(orderId){
    let data = {
      Order_Id: orderId
    }
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/borrower/' + orderId;
    return this.http.get(url,httpOptions).pipe(
      map((response: any) =>  response),
      catchError((error: any) => observableThrowError(error.error)));
    
  }

  updateBorrowerFindings(borrower_ID,newData){
    let data = {
      Order_Borrower_ID: borrower_ID,
      Order_Borrower_Finding :newData,
      modifiedBy: this.auth.getUserId()
    }
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/updateBorrower/findings';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }


  editCoBorrowerDetails(updatedData,orderId){
    updatedData['Admin_ID'] = this.auth.getUserId();
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
    }
    let url = this.config.getBasePath() + '/orders/'+orderId+ '/edit/borrower';
    return this.http.put(url, updatedData ,httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));

 }

 approveOrRejectSitusAMCOrder(pippin_order_id, action){
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    const updateData = {
      pippin_order_id,
      action
    };

    console.log("\n\nHERE\n\n", updateData);
    console.log("\n\nHERE2\n\n", this.config.getBasePathSituSAMCService());

    const url = this.config.getBasePathSituSAMCService() + '/order/action';
    return this.http.patch(url, updateData ,httpOptions).pipe(
      map((response: any) => {
        console.log("\n\nHERE2\n\n", response);
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
 }


 getSubNBaseProduct(userId) {
  let httpOptions = {
    headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
  }
  let url = this.config.getBasePath() + '/orders/product/getAll/' + userId;
  return this.http.get(url, httpOptions).pipe(
    map((response: any) => {
     return response;
    }),
    catchError((error: any) => observableThrowError(error.error)));
}

getSubNBaseProductforCustom(userId) {
  let httpOptions = {
    headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
  }
  let url = this.config.getBasePath() + '/orders/customproduct/getAll/' + userId;
  return this.http.get(url, httpOptions).pipe(
    map((response: any) => {
     return response;
    }),
    catchError((error: any) => observableThrowError(error.error)));
}

  checkOrderTags(orderId, adminId) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/checktags/order/' + orderId + '/admin/' + adminId;
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  completeReswareOrder(data) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/resware/complete';
    return this.direct.put(url, data, httpOptions).pipe(
      map((result: any) => {
        if(result && result.Message && result.Message.toLowerCase().includes('with exceptions')){
          this.userMessageSerice.setQueueMsg('Pippin Order Number ( '+ data.Order_ID + ') - ' + result.Message);
        }
        else this.userMessageSerice.setSuccessMsg(result && result.Message ? 'Pippin Order Number ( '+ data.Order_ID + ') - ' + result.Message : 'Pippin Order Number ( '+ data.Order_ID + ') - Search Data received successfully on remote file')
      }),
      catchError((error: any) => {
        this.userMessageSerice.setErrorMsg(error.error.msg)
        return observableThrowError(error.error)
      }));
  }

  completeQualiaOrder(data) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/qualia/complete';
    return this.direct.put(url, data, httpOptions).pipe(
      map((result: any) => {
        this.userMessageSerice.setSuccessMsg('Pippin Order Number ( '+ data.Order_ID + ') - Title Commitment submitted successfully to Qualia file')
      }),
      catchError((error: any) => {
        this.userMessageSerice.setErrorMsg(error.error.msg)
        return observableThrowError(error.error)
      }));
  }

  getQualiaOrderDetails(orderId) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/qualiaorder/details/' + orderId;
    return this.direct.get(url, httpOptions).pipe(
      map((result: any) => {
        return result;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getQualiaCommitmentTypes(qualiaOrderId){
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getQualiaMsURL() + '/orders/commitment/templates/' + qualiaOrderId;
    return this.direct.get(url, httpOptions).pipe(
      map((result: any) => {
        return result;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getDailyDueCount(){
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/daily/due/' + this.auth.getUserId();
    return this.direct.get(url, httpOptions).pipe(
      map((result: any) => {
        return result;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  // getAssignedDuration(startTime, endTime){
  //   endTime = new Date(momentTimeZone.tz('America/New_York').utc().format()).getTime(); //Always take current time as end time
  //   let diff = endTime - new Date(startTime).getTime();
  //   // let hours = Math.floor(diff / (60 * 60 * 1000));
  //   // let minutes = Math.floor(diff / (60 * 1000)) - (hours * 60);
  //   // return `(${hours}h ${minutes}m)`;
  //   // console.log("startTime=>", startTime, "======diff=>", diff)
  //   var days = Math.floor(diff / (1000 * 60 * 60 * 24));
  //   let hours = Math.floor(diff / (60 * 60 * 1000)) - (days * 24);
  //   let minutes = Math.floor(diff / (60 * 1000)) - (((days * 24) + hours) * 60);
  //   if(days > 0) return `(${days}d ${hours}h ${minutes}m)`;
  //   else if(hours > 0) return `(${hours}h ${minutes}m)`;
  //   else if(minutes > 0) return `(${minutes}m)`;
  //   else return '';
  // }

  getLastAssignedDuration(order, endTime){
    // if([CONSTANTS.orderStatus.cancelled, CONSTANTS.orderStatus.completed].includes(order.Order_Status)) 
    //   endTime = order.Order_Modification_Date !== undefined ? 
    //     new Date(order.Order_Modification_Date).getTime() : new Date(order.Order_Completion_Date).getTime();
    if([CONSTANTS.orderStatus.cancelled, CONSTANTS.orderStatus.completed].includes(order.Order_Status)) return '';
    else {
      let diff = endTime - new Date(order.Order_Assigned_Date).getTime();
      // let hours = Math.floor(diff / (60 * 60 * 1000));
      // let minutes = Math.floor(diff / (60 * 1000)) - (hours * 60);
      // return `(${hours}h ${minutes}m)`;
      var days = Math.floor(diff / (1000 * 60 * 60 * 24));
      let hours = Math.floor(diff / (60 * 60 * 1000)) - (days * 24);
      let minutes = Math.floor(diff / (60 * 1000)) - (((days * 24) + hours) * 60);
      if(days > 0) return `(${days}d ${hours}h ${minutes}m)`;
      else if(hours > 0) return `(${hours}h ${minutes}m)`;
      else if(minutes > 0) return `(${minutes}m)`;
      else return '';
    }
  }

  getEscalatedOrders(type, pageNum, sortBy, orderBy, searchStr, filterBy, selectedTags,negativeTags, isDemoFlag,order_Status, pageSize = this.config.getNumOrdRecordsPerPage()){
    var data = {
      filterBy: filterBy,
      Selected_Tags: selectedTags,
      negativeTags,
      isDemo: isDemoFlag,
      order_Status: order_Status
    }
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url;
    if (type == "my") {
      url = this.config.getBasePath() + '/orders/admin/escalatedorders/adminId/' + this.auth.getUserId();
    } else {
      url = this.config.getBasePath() + '/orders/admin/escalatedorders/all/' + this.auth.getUserId();
    }
    url = url + '/page/' + pageNum + '/size/' + pageSize;
    if (sortBy != '') {
      url = url + '/sort/' + sortBy + '/order/' + orderBy;
    }
    if (searchStr) {
      url = url + '/search/' + searchStr;
    }
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        this.escalatedOrderCnt = response.count;
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getEscalatedOrderCount() {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/admin/escalatedorders/count/' + this.auth.getUserId();
    return this.direct.post(url, { Admin_ID: this.auth.getUserId() }, httpOptions).pipe(
      map((response: any) => {
        this.escalatedOrderCnt = response;
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getVendorOrders(type, pageNum, sortBy, orderBy, searchStr, filterBy, selectedTags,negativeTags, isDemoFlag, pageSize = this.config.getNumOrdRecordsPerPage()){
    var data = {
      filterBy: filterBy,
      Selected_Tags: selectedTags,
      isDemo: isDemoFlag,
      negativeTags
    }
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url;
    if (type == "my") {
      url = this.config.getBasePath() + '/orders/admin/vendororders/adminId/' + this.auth.getUserId();
    } else {
      url = this.config.getBasePath() + '/orders/admin/vendororders/all/' + this.auth.getUserId();
    }
    url = url + '/page/' + pageNum + '/size/' + pageSize;
    if (sortBy != '') {
      url = url + '/sort/' + sortBy + '/order/' + orderBy;
    }
    if (searchStr) {
      url = url + '/search/' + searchStr;
    }
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        this.escalatedOrderCnt = response.count;
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }


  setEscalationForRushOrders(orderId, isEscalatedOrder) {
    var data = {
      Order_ID: orderId,
      Escalated_Order: isEscalatedOrder,
      Admin_User_ID: this.auth.getUserId()
    }
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      observe: 'response' as 'body'
    }
    let url = this.config.getBasePath() + '/orders/set/rushorder/escalation';
    return this.http.put(url, data, httpOptions).pipe(
      map((response: any) => {
        // console.log("response", response);
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  createEscalatedOrdsExcelReport(filterBy, searchStr, sortField, orderBy, selectedTags,negativeTags, listingType, isDemoFlag,order_Status) {
    var data = {
      filterBy: filterBy,
      searchStr: searchStr,
      Admin_User_ID: this.auth.getUserId(),
      Selected_Tags: selectedTags,
      negativeTags,
      sortField: sortField,
      orderBy: orderBy,
      listingType: listingType,
      Admin_User_Role: this.auth.getUserRole(),
      isDemo: isDemoFlag,
      order_Status: order_Status
    }
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() }),
      responseType: 'blob' as 'json',
    }
    let url = this.config.getBasePath() + '/reports/escalatedOrds/create/excel';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  insertOrderView(orderId) {
    var data = {
      Order_ID: orderId,
      Admin_User_ID: this.auth.getUserId(),
    }
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/insert/orderview';
    return this.direct.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getUnassignedOrders() {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/unassigned';
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

 

  postQCChecklist(data) {
    data.Admin_User_ID = this.auth.getUserId();
    let httpOptions = {
      headers: new HttpHeaders({
        'Authorization': 'Bearer ' + this.auth.getToken()
      })
    }
    let url = this.config.getBasePath() + '/orders/admin/add/qcchecklist';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error))
    )
  }

  getQCCompanyChecklistTypes(cmpId) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let data = {
      Organisation_ID: cmpId,
      Order_Stage: 1
    }
    let url = this.config.getBasePath() + '/orders/companychecklist';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  getOrderProductEntries(quoteId) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + `/orders/quote/${quoteId}/product/entries`;
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }
  calcAssignedDuration(Orders:any=[]){
    return interval(10000).subscribe(x=>{
      let assignedDurationsObj={};
      Orders.forEach(order=>{
        assignedDurationsObj[order.Order_ID]=this.getAssignedDuration(order);
      });
      this._assignedDurations$.next(assignedDurationsObj);
    })
  }
  getAssignedDuration(order){
    let startTime=order.Order_Assigned_Date;
    if([CONSTANTS.orderStatus.cancelled, CONSTANTS.orderStatus.completed,CONSTANTS.orderStatus.quotecompleted].includes(order.Order_Status)) return '';
    else {
      if(startTime==null) startTime=order.Order_Creation_Date;
      let endTime = new Date(momentTimeZone.tz('America/New_York').utc().format()).getTime(); //Always take current time as end time
      let diff = endTime - new Date(startTime).getTime();
      var days = Math.floor(diff / (1000 * 60 * 60 * 24));
      let hours = Math.floor(diff / (60 * 60 * 1000)) - (days * 24);
      let minutes = Math.floor(diff / (60 * 1000)) - (((days * 24) + hours) * 60);
      if(days > 0) return `(${days}d ${hours}h ${minutes}m)`;
      else if(hours > 0) return `(${hours}h ${minutes}m)`;
      else if(minutes > 0) return `(${minutes}m)`;
      else return '';
    }
  }

  sortTags = (a: any, b: any): any => {
    return a.Tag_Color.localeCompare(b.Tag_Color) || a.Tag_Name.localeCompare(b.Tag_Name);
  };
  
  getPredictedETA(productId, state, county) {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let data = {
      Product_ID: productId,
      State: state,
      County: county
    }
    let url = this.config.getBasePath() + '/orders/predictETA';
    return this.http.post(url, data, httpOptions).pipe(
      map((response: any) => {
        return response;
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }


  getDocumentCategories() {
    let httpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + this.auth.getToken() })
    }
    let url = this.config.getBasePath() + '/orders/document/categories';
    return this.http.get(url, httpOptions).pipe(
      map((response: any) => {        
        this._documentCategories$.next(response);
      }),
      catchError((error: any) => observableThrowError(error.error)));
  }

  updateDocumentCategory(orderDocId, docCatId){
    var data = {
      Order_Document_ID: orderDocId,
      Doc_Cat_ID: docCatId,
      Modified_By: this.auth.getUserId(),
    };
    let httpOptions = {
      headers: new HttpHeaders({
        Authorization: "Bearer " + this.auth.getToken(),
      }),
    };
    let url =
      this.config.getBasePath() +
      "/orders/document/category/update";
    return this.http.put(url, data, httpOptions).pipe(
      map((response: any) => response),
      catchError((error: any) => observableThrowError(error.error)))
  }

}

