import {
  Component,
  ViewChild,
  ElementRef,
  ViewEncapsulation,
  Inject,
} from "@angular/core";
import { DialogComponent, DialogService } from "ng2-bootstrap-modal";
import { ConfigService, AbstractorService } from "../../services";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  FormControl,
  Validators,
} from "@angular/forms";
import { AbsQuoteMessageComponent } from "../../dialog/abs-quote-message/abs-quote-message.component";
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from "@angular/material/dialog";

@Component({
  selector: "app-new-abs-quote",
  templateUrl: "./new-abs-quote.component.html",
  styleUrls: ["./new-abs-quote.component.scss"],
})
export class NewAbsQuoteComponent {
  registerFrm: UntypedFormGroup;
  error: string = "";
  errorMsg: any = {};
  errorType: string = "";
  registerModel: any = {};
  message: any = {};
  itemInput = "";
  sendReminder: boolean = true;
  users = [];
  selectedAbstractors = [];
  timer: any = [];
  keywords = ["Email_Address", "Abstrator_Full_Name"];

  constructor(
    public dialogRef: MatDialogRef<NewAbsQuoteComponent>,
    private config: ConfigService,
    private fb: UntypedFormBuilder,
    private matDialog: MatDialog,
    private abstractorService: AbstractorService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.message = this.data.message;
    this.registerFrm = fb.group({
      User_First_Name: [
        null,
        Validators.compose([Validators.required, Validators.maxLength(127)]),
      ],
      Email_Address: [
        null,
        Validators.compose([Validators.required, Validators.maxLength(255)]),
      ],
      Confirm_Email_Address: [null, Validators.required],
      User_SSN: [null, Validators.maxLength(11)],
      User_EIN: [null, Validators.maxLength(10)],
      Contact_Num: [null],
      Contact_Type: [null],
    });
    this.registerFrm.valueChanges.subscribe((data) => {
      this.error = "";
    });
  }
  ngOnInit() {
    this.initDialog();
    this.registerModel.Contact_Num = "";
    this.registerModel.Contact_Type = "M";
    this.timer.push(
      setTimeout(() => {
        this.abstractorService.getAllAbstractorsList().subscribe((users) => {
          this.users = users;
        });
      }, 0)
    );
  }

  initDialog(){
    this.message = this.data.message;
  }

  clearError() {
    this.errorMsg.msg = "";
  }

  Close() {
    this.dialogRef.close(false);
    // this.result = false;
    this.clearTime();
  }

  clearTime() {
    this.dialogRef.close();
    // this.close();
    this.timer.forEach((time) => {
      clearTimeout(time);
    });
  }

  getOptionDisplayTemplate(item) {
    return (
      "<span>" +
      (item.Abstrator_Full_Name ? item.Abstrator_Full_Name : "-N.A-") +
      "</span>" +
      "&nbsp;(" +
      item.Email_Address +
      ")"
    );
  }

  selectEvent(item) {
    this.assignAbsuser(item);
  }
  assignAbsuser(abstractor) {
    this.registerModel.User_First_Name = abstractor.User_First_Name;
    this.registerModel.Email_Address = abstractor.Email_Address;
    this.registerModel.Confirm_Email_Address = abstractor.Email_Address;
    this.registerModel.User_SSN = abstractor.User_SSN
      ? abstractor.User_SSN
      : "";
    this.registerModel.User_EIN = abstractor.User_EIN
      ? abstractor.User_EIN
      : "";
    this.registerModel.Contact_Num = abstractor.Contact_Num
      ? abstractor.Contact_Num
      : "";
    this.registerModel.Contact_Type = abstractor.Contact_Type
      ? abstractor.Contact_Type
      : "M";
  }

  createAbstractor() {
    this.errorMsg = {};
    let selectedAbs = Object.assign({}, this.registerModel);
    selectedAbs.Abstrator_Full_Name = this.registerModel.User_First_Name;
    this.selectedAbstractors = [];
    this.selectedAbstractors.push(selectedAbs);
    this.matDialog
      .open(AbsQuoteMessageComponent, {
        data: {
          message: {
            orderDetails: this.message["order"],
            clientInfo : this.message.clientInfo.Note_Text,
            selectedAbstractors: this.selectedAbstractors,
            instructions: {
              Search_Instructions: this.message["Search_Instructions"],
              Special_Instructions: this.message["Special_Instructions"],
            },
          },
        },
        ...this.config.getDialogOptions(),
      })
      .afterClosed()
      .subscribe((res) => {
        if (res && res.state) {
          // this.registerModel.selectedAbstractors = this.selectedAbstractors;
          let Product_Description;
          if(res.selectedProductName) Product_Description = res.selectedProductName;
          else {
              Product_Description = this.message["order"].Product_Description;
              if (Product_Description && Product_Description.includes("Plus")) {
                var lastIndex = Product_Description.lastIndexOf(" ");
                Product_Description = Product_Description.substring(0, lastIndex);
              }
          }
          this.registerModel.Message_Text = res.absQuoteMessage;
          this.registerModel.fromEmailId = res.selectedEmailId;
          (this.registerModel.Message_Subject =
            "Pippin Title Order #" +
            this.message["order"].Order_ID +
            " - " +
            Product_Description +
            " Quote/ETA Request - " +
            this.message["order"].Property_County +
            " County, " +
            this.message["order"].Property_State_Abbr),
            (this.registerModel.Order_ID = this.message["order"].Order_ID);
          this.abstractorService
            .createAbsOnFlyToSendQuote(
              this.registerModel,
              this.selectedAbstractors
            )
            .subscribe(
              (res) => {
                // this.dialogRef.close(true);
                this.dialogRef.close({
                  state: true,
                });
                // this.result = true;
              },
              (err) => {
                this.errorMsg = err;
              }
            );
        }
      });

    // let disposable = this.matDialog.open(AbsQuoteMessageComponent, {
    //   message: {
    //     orderDetails: this.message['order'], selectedAbstractors: this.selectedAbstractors, instructions: { Search_Instructions: this.message['Search_Instructions'], Special_Instructions: this.message['Special_Instructions'] }
    //   }
    // }, this.config.getDialogOptions())
    //   .subscribe((res) => {
    //     if (res && res.state) {
    //       // this.registerModel.selectedAbstractors = this.selectedAbstractors;
    //       let Product_Description = this.message['order'].Product_Description
    //       if(Product_Description.includes('Plus')){
    //         var lastIndex = Product_Description.lastIndexOf(" ");
    //         Product_Description = Product_Description.substring(0, lastIndex);
    //       }
    //       this.registerModel.Message_Text = res.absQuoteMessage;
    //       this.registerModel.Message_Subject = 'Pippin Title Order #'+ this.message['order'].Order_ID+' - ' +Product_Description+' Quote/ETA Request - '+this.message['order'].Property_County+' County, '+this.message['order'].Property_State_Abbr,
    //       this.registerModel.Order_ID = this.message['order'].Order_ID;
    //       this.abstractorService.createAbsOnFlyToSendQuote(this.registerModel, this.selectedAbstractors)
    //         .subscribe((res) => {
    //           this.result = true;
    //           this.clearTime();
    //         }, (err) => {
    //           this.errorMsg = err;
    //         });
    //     }
    //   })
  }
}
